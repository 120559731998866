.menubg {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(29, 28, 29, 0);
  }
  
  .menubg:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .1;
    padding: 5rem 0;
  }


.menu-content {
    top: 10%;
}

.menu-header {
    text-align: center;
    padding: 3rem 1rem;
}


.menu-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 2rem;
}

.menu-flexItem {
    background-color: white;
    width: 30%;
    height: auto;
    border-radius: 1rem;
    -webkit-box-shadow: -7px -9px 25px -14px rgba(0,0,0,0.75);
    -moz-box-shadow: -7px -9px 25px -14px rgba(0,0,0,0.75);
    box-shadow: -7px -9px 25px -14px rgba(0,0,0,0.75);
}

.menu-flexItem-img {
    margin: auto;
    width: 100%;
    padding: 1rem 2rem;
}

.menu-flexItem-img img {
    width: 100%;
    display: flex;
    justify-content: center;
}

.menu-flexItem h1  {
    text-align: center;
    font-size: 1.2rem;
    padding: .5rem;
    font-weight: normal;
}

.menu-flexItem p {
    text-align: center;
    padding: 1rem;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.menu-flexItem-btn {
    text-align: center;
}

.menu-flexItem-btn button {
    background-color: white;
    color: black;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 14px;
}

@media screen and (max-width: 940px) {
    .menu-flexItem {
        background-color: white;
        width: 45%;
    }
}

@media screen and (max-width: 700px) {
    .menu-flexItem {
        width: 90%;
    }
}