@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background: #00000000;
    position: absolute;
    text-align: center;
}



.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: .5rem;
}


.navbar-logo {
  z-index: 9999999;
  width: 150px;
}

.navbar-logo img {
  width: 100%;
  padding: 1rem;
}

.navbar .icon {
  font-size: 2rem;
  color: #fff;
}



.hamburger {
    display: none;
    padding: .5rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 1rem;
}
.navbar li {
    width: max-content;
    padding: .3rem 1rem;
}


.navbar li a {
  color: rgb(224, 226, 225);
  font-family: 'roboto', sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}


  .menu-item .sub__menus li a {
    color: #000000;
    font-size: 13px;
    padding: -1rem ;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(255, 123, 0) !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: white;
    border: 1px solid lavender;
    border-radius: 5px;
    width: auto;
    left: -5rem;
    padding: 2px 0 0 0;
    margin-top: 4px;
    z-index: 1000;
  }



  /* -----------------------------------------------------> DROPDOWN ARROW */
  .sub__menus__arrows {
    position: relative;
  }

  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 8px;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }
  
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    top: 30px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }



@media screen and (max-width: 940px) {
  .sub__menus__arrows svg, .sub__menus__arrows__full svg {
    padding-top: 0px;
    position: absolute;
    top: 15px;
  }

  .nav-box {
    justify-content: space-between;
}

    .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .navbar ul li {
      width: 100%;
      text-align: left;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.158);
    }

    .navbar li a {
      color: rgb(31, 29, 29);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 70%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(255, 255, 255);
        transition: 0.5s ease-in;
    }

    .sub__menus {
      width: 100%;
      left: 0rem;

    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1.5rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 13px;
      }

      li .submenu-header {
        font-size: 14px;
      }


}





