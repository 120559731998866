@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.treatment-overview {
    padding: 3rem 1rem 
}

.treatment-overview p {
    padding: 1rem 0;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
}

.treatment-overview h1 {
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: .5rem 0;
}


@media screen and (max-width: 940px) {
    .treatment-overview h1 {
        font-size: 1rem;
    }
}


