.mission-section {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    position: relative;
    background: rgba(255, 255, 255, 0);
}

.mission-section .mission-section-left {
    width: 50%;
    margin: auto;
}

.mission-section .mission-section-left h1 {
    text-align: center;
    padding: 1rem;
    font-weight: normal;
}

.mission-section .mission-section-left p {
    text-align: justify;
    padding: 1rem;
    font-weight: normal;
    font-family: roboto;
}

.mission-section .mission-section-right {
    width: 50%;
}

.mission-section .mission-section-right img {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
}





@media screen and (max-width: 940px) {

    .mission-section .mission-section-left {
        width: 100%;
    }

    .mission-section {
        flex-wrap: wrap;
    }

    
}






