@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.mental-health {
  padding: 5rem 0;
}

.mental-health-header h1 {
  padding: 1rem; 
  color: rgb(8, 11, 56);
}

.mental-health-header p {
  padding: 1rem;
}

.mental-health-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
}

.mental-health-card {
  width: 250px;
  font-family:'Roboto';
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 17px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 17px -3px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 17px -3px rgba(0, 0, 0, 0.233);
}


.mental-health-card img
{
  width:100%;
  transition: all 0.4s ease-out;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.card-body h3 {
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 0;
}

.mental-health {
  text-align: center;
}


.mental-health button {
  background-color: rgb(190, 103, 31);
  border: none;
  font-size: .8rem;
  padding: .5rem 2rem;
}

.mental-health button:hover {
  background-color: rgb(14, 14, 71);
  color: white;
}


@media screen and (max-width:700px) {
  .mental-health-card {
    width: 90%;
  }
}