@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
.mh {
    width: 100%;
    height: 70vh;
    background: rgba(21, 3, 27, 0.521);
    position: relative;
  }
  
  .mh:before {
    content: '';
    position: absolute;
    background: url('../../assets/mh-mainmenu-bg-min2.jpg') no-repeat center  center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.mh {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mh .mh-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
}


.mh h1 {
    color: white;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.mh h1 {
    font-size: 2rem;
}

}