@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.mh-iop {
    width: 100%;
    height: 70vh;
    background: rgba(22, 22, 22, 0.658);
    position: relative;
  }
  
  .mh-iop:before {
    content: '';
    position: absolute;
    background: url('../../assets/mh-submenu-bg-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.mh-iop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.mh-iop .mh-iop-content {
    display: block;
    position: absolute;
    top: 50%;
    padding: 1rem;
    width: 100%;
}



.mh-iop h1 {
    color: white;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.mh-iop h2 {
    color: white;
    font-size: 1.2rem;
    letter-spacing: 7px;
}


/* MEDIA QUERIES */


@media screen and (max-width:940px) {

    
    .mh-iop .mh-iop-content h1 {
        font-size: 2rem;
    }
    
    }