@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.mission {
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #18171777;
  }
  
  .mission:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-mission.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  

.mission .mission-content {
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
    width: 60%;
}

.mission p {
    color: white;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.mission h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;

}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.mission .mission-content {
    width: 100%;
}

.mission h1 {
    font-size: 2rem;
}
}