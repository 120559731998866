@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact {
    width: 100%;
    height: 70vh;
    position: relative;
    background: #0000003a;
  }
  
  .contact:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-contact-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.contact .contact-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    width: 100%;
}

.contact h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}

.contact h2 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}

.contact p {
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {
    
    .contact h1 {
        font-size: 1.5rem;
    }
    
    .contact h2 {
        font-size: 1.5rem;
    }
    
    }