@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.insurance {
    width: 100%;
    height: 70vh;
    position: relative;
    background: #0000003a;
  }
  
  .insurance:before {
    content: '';
    position: absolute;
    background: url('../../assets/menu-bg-insurance-min.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.insurance .insurance-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    width: 100%;
}

.insurance h1 {
    font-size: 4rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {
    
    .insurance h1 {
        font-size: 3rem;
        text-align: center;
    }
    
    }