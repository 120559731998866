.footer {
    width: 100%;
    height: auto;
    background: rgb(0, 0, 0);
}



.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
    width: 120px;
}

.logo-footer img {
    width: 100%;
}



.footer .top .icon {
    font-size: 3rem;
    margin: auto;
    color: rgb(189, 90, 9);
    cursor: pointer;
}


.footer .col-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem 1rem;
    width: 100%;
}

.col {
    width: 30%;
}

.col h3 {
    color: white;
    text-align: center;
    padding: 1rem 0;
}


.col p {
    margin: .5rem 0;
    color: rgb(223, 231, 233);
    text-align: center;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    border: 1px solid #aaa;
    border-radius: 4px;
    color: #333;
}

.footer form input:focus {
    color: #333;
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-dark);
}


.copyright p {
    color: rgb(218, 219, 219);
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
}




.license {
    width: 100%;
    text-align: center;
}



.license h3 {
    text-align: center;
}

.license p {
    text-align: center;
    color: white;
}

.license a {
    text-align: center;
    color: white;
}

.license a:hover {
    text-align: center;
    color: rgb(189, 90, 9);
}


.license-image {
    width: 150px;
    margin: auto;
    padding: .5rem;
}

.footer .license img {
    width: 100%;
}


@media screen and (max-width: 700px) {
    .col {
        width: 100%;
    }
}


