@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charm:wght@400;700&display=swap');


.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: rgba(0, 0, 0, 0.315);
}

.hero:before {
  content: '';
  position: absolute;
  background: url('../../assets/hero_image.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
}


.hero .hero-container {
    width: 100%;
}

.hero .content {
  width: 80%;
  bottom: 20%;
  right: 5%;
  position: absolute;
  padding: 1rem;
  text-align: center;
}


.hero h1 {
    font-size: 8rem;
    color: rgb(255, 255, 255);
    font-family: "Charm", serif;
    font-weight: 700;
    font-style: normal;
}

.hero h2 {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    font-weight: normal;
}

.hero p {
    color: white;
    font-style: italic;
    padding: .5rem 0;
}

.hero-btn button {
    background-color: white;
    color: rgb(37, 35, 34);
    font-size: 14px;
    letter-spacing: 2px;
    padding: .8rem 1.8rem;
    border-radius: 5px;
}

/* MEDIA QUERIES */

@media screen and (max-width:940px) {

  .hero .content {
    width: 100%;
    top: 50%;
    left: 0%;
  }

    .hero h1 {
        font-size: 6rem;
        text-align: center;
    }

    .hero h2 {
        font-size: 1.5rem;
        text-align: center;
    }

    .hero p {
      font-size: 1rem;
      color: rgb(255, 255, 255);
  }
  

}


@media screen and (max-width: 600px) {
    .hero h1 {
        font-size: 4rem;
        line-height: 4.5rem;
    }

    .hero h2 {
        font-size: 1rem;
    }

    .hero p {
        font-size: .8rem;
    }
}