.section1 {
    padding: 3rem 0;
}

.section1-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    margin: auto;
}

.section1-box {
    width: auto;
    height: auto;
    padding-top: 2rem;

}

.section1-box1 {
    background-color: #6D4321;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 33%;
}

.section1-box2 {
    background-color: #E2AB7F;
    width: 33%;

}

.section1-box3 {
    background-color: #dfdad6;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 33%;

}


.section1-flexBox {
    justify-content: center;
    text-align: center;
    width: 100%;
}


.section1-flexBox .title {
    text-align: center;
    
}

.section1-icon {
    color: white; 
    font-size: 2.2rem;
    margin: auto;
}

.section1-box h1 {
    font-size: 1.2rem;
    color: white;
    text-align: center;
}

.section1-box p {
    line-height: 25px;
    font-size: 15px;
    text-align: center;
}

.section1-caption {
    padding: 1rem;
    text-align: center;
    color: white;
}

.section1-box button {
    color: white;
    padding: 1rem 2rem;
    background-color: #E2AB7F;
    border-radius: 5px;
    border: none;
    font-size: .8rem;
    letter-spacing: 3px;
    margin: 1rem 0;
}

.section1-caption2 button{
    background-color: #aa764c;
}

.section1-caption1 button:hover{
    background-color: #41220a;
}

.section1-caption2 button:hover{
    background-color: #41220a;
}

.section1-caption3 button:hover{
    background-color: #41220a;
}

.section1-flexItem3 h1 {
    color: black;
}

.section1-flexItem3 h3 {
    color: #6D4321;
}

.section1-caption3 p {
    color: #6D4321;
}

@media screen and (max-width:940px)  {

    .section1-content {
        flex-wrap: wrap;
    }

    .section1-icon {
        width: 100%;
    }

    .section1-flexBox {
        flex-wrap: wrap;
    }

    .section1-flexItem1 {
        width: 100%;
    }

    .section1-flexBox {
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: .8rem 0;
        margin: auto;
        text-align: center;
    }

    .section1-box button {
        margin: 1rem 0;
    }

    .section1-box1 {
        border-bottom-left-radius: 0rem;
        border-top-right-radius: 1rem;
        width: 100%;
    }

    .section1-box2 {
        width: 100%;
    }
    
    .section1-box3 {
        border-top-right-radius: 0rem;
        border-bottom-left-radius: 1rem;
        width: 100%;
    }

}