.interpersonal {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .interpersonal:before {
    content: '';
    position: absolute;
    background: url('../../assets/plant-bg1.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .02;
  }

  .interpersonal-content {
    padding: 1rem 0;
    width: 100%;
  }

  .interpersonal-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .interpersonal-left {
    width: 60%;
    margin: auto;
    padding: 1rem;
  }

  .interpersonal-left h1 {
    padding: 1rem 0;
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  .interpersonal-left p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
    text-align: justify;  
  }

  .interpersonal-right {
    padding: 1rem;
    width: 450px;
    margin: auto;
  }

  .interpersonal-right img {
    width: 100%;
    border-radius: 1rem;
  }

  @media  screen and (max-width:1200px) {
    .interpersonal-left {
        width: 100%;
      }
      .interpersonal-left h1 {
        text-align: center;
        font-size: 1rem;
      }


  }